import { createVueMatomo } from '@friss/user-tracking/vue';
import config from '@/config';

const getMatomo = async function () {
  if (config.VUE_APP_TRACKING_ENABLED) {
    return await createVueMatomo({
      host: config.VUE_APP_TRACKING_SERVER_URL ?? '',
      siteId: config.VUE_APP_TRACKING_SITE_ID ?? ''
    });
  }
  return null;
};

export default await getMatomo();
